<template>
  <div>
    <p v-if="showText==1">您已领取过证书</p>
    <p v-if="showText==2">您还未领取证书</p>
  </div>
</template>
<script>
export default {
  name: "certificate",
  data() {
    return {
        showText:''
    };
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    getInfo(){
        this.$axios.post(`/v1/personal/lmgxxyIsGetCert`,{},{useLog:true}).then(res=>{
            console.log(res)
            if(res.code==0){
                // 已领取过证书
                this.showText = 1
            }
            if(res.code==1){
                // 未领取过证书
                this.showText = 2
            }
        })
    }
  },
};
</script>
<style scoped lang="scss">
p{
    font-size: 18px;
}
</style>